import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './Message_Header.css'

export default() =>{
    const data = useStaticQuery(graphql`
    query MessageQuery{
      file(relativePath: { eq: "Sundayworship_images/sundayworship.jpg" }) {
        childImageSharp {
          fluid (maxWidth:2000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)

    return (
        <div class='message_header_Container'> 
            <BackgroundImage fluid={data.file.childImageSharp.fluid} className='message_header_Background' alt="Jesus is smiling at you">
                <div>
                    <h1 class='message_header_Title'>主 日 信 息</h1> 
                    <p class='oneVerse'> 不要愛世界和世界上的事。人若愛世界，愛父的心就不在他裡面了。因為凡世界上的事，就像肉體的情慾，眼目的情慾，並今生的驕傲，都不是從父來的，乃是從世界來的。 <br/>
							                                                        這世界和其上的情慾都要過去，惟獨遵行神旨意的，是永遠常存。<br/>
                                                                                        <br/>
                                                                                        約翰壹書 第二章 14-17
                    </p>

                </div>
            </BackgroundImage>
            <h2 class='message_theme'>2020主题：连于基督 彼此相爱</h2>


            <p class = 'message_content'>
              
              异象：仰望耶稣 ，建立教会建立家 <br/>

              使命：生命更新， 广传福音 <br/>
              ​​​
              行动：培育和实践我们的核心价值观 <br/>


              敬畏神 &nbsp; 圣经为依据 &nbsp; 基督为中心 <br/>

              得力于祷告 &nbsp;  个人布道 &nbsp;  领人归主  <br/>

              团队事奉  &nbsp;  彼此相爱  &nbsp;   谦卑合一 <br/>
            </p>
        </div>
        
    )
}