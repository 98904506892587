import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import GlobalFooter from '../components/Footer/GlobalFooter'
import Message_Header from '../components/Message/Message_Header'
import Weekly_Message from '../components/Message/Weekly_Message'

export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <Message_Header />
        </div>
        <div>
          <Weekly_Message />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }